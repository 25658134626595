import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import MissionVision from "../components/who-we-are/mission-vision"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import SubHeader from "../components/sub-header"
import ProjectItems from "../components/projects/project-items"
import NewsItem from "../components/news/news-item"
import Link from "gatsby-link"
import ProjectNews from "../components/projects/project-news"

export default function PageTemplate({ data: { project, news } }) {
  const image = getImage(project.image.image.localFile)
  const actionTexts = project.projectContent.actionText
  const data = {
    title: project.projectContent.headerTitle,
    imageCover: (
      <GatsbyImage
        image={image}
        alt={project.projectContent.headerTitle}
        className="w-full h-full "
      />
    ),
    image: (
      <GatsbyImage
        image={image}
        alt={project.projectContent.headerTitle}
        className="w-full h-full "
      />
    ),
    description: project.projectContent.subHeader,
  }

  const projectNews =
    news.nodes.length > 0 ? (
      <ProjectNews project={project} news={news} title={data.title} />
    ) : null

  const schoolPartners =
    project.id === "cG9zdDo5Ng==" ? (
      <>
        <h3 className="mb-5 mt-16 text-center text-3xl md:text-4xl leading-8 font-semibold pt-2">
          Our Partner Schools
        </h3>
        <div className="flex justify-center my-16 flex-wrap">
          <StaticImage
            src="../images/school/hnu.png"
            alt={""}
            objectFit="object-cover"
            className="w-1/3 md:w-1/5 p-2 "
          />
          <StaticImage
            src="../images/school/uc.png"
            alt={""}
            objectFit="object-cover"
            className="w-1/3 md:w-1/5 p-2 "
          />
          <StaticImage
            src="../images/school/com.png"
            alt={""}
            objectFit="object-cover"
            className="w-1/3 md:w-1/5 p-2"
          />
          <StaticImage
            src="../images/school/abc.png"
            alt={""}
            objectFit="object-cover"
            className="w-1/3 md:w-1/5 p-2 mt-8 md:mt-0"
          />
          <StaticImage
            src="../images/school/uos.png"
            alt={""}
            objectFit="object-cover"
            className="w-1/3 md:w-1/5 p-2 mt-8 md:mt-0"
          />
        </div>
      </>
    ) : null

  const actionItems = actionTexts ? (
    <>
      <ProjectItems text={actionTexts} items={project.projectContent.items} />
    </>
  ) : null

  const map =
    project.id === "cG9zdDoxMDI=" ? (
      <>
        <iframe
          src="https://www.google.com/maps/d/embed?mid=1j2KNarG73BLZLywxodIgDecYvZnp51xa"
          width="100%"
          height="640"
        ></iframe>
      </>
    ) : null

  return (
    <Layout>
      <MissionVision data={data} />
      <div className="relative max-w-7xl mx-auto ">
        {schoolPartners}
        <div className="mx-5">
          <SubHeader text={project.content.replace(/<[^>]+>/g, "")} />
          {actionItems}
        </div>
      </div>
      {map}
      {projectNews}
    </Layout>
  )
}

export const pageQuery = graphql`
  query getProject($id: String!, $databaseId: Int!) {
    # selecting the current post by id
    project: wpProject(id: { eq: $id }) {
      id
      title
      slug
      content
      image {
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      projectContent {
        actionText
        fieldGroupName
        headerTitle
        items
        subHeader
        order
      }
    }
    news: allWpPost(
      sort: { order: DESC, fields: date }
      limit: 4
      filter: { projectCategoryId: { in: [$databaseId] } }
    ) {
      nodes {
        title
        excerpt
        slug
        date(formatString: "MMMM DD, YYYY")
        projectCategoryId
        project_category {
          project {
            ... on WpProject {
              id
              title
            }
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 100) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`
