import Link from "gatsby-link"
import NewsItem from "../news/news-item"
import React from "react"

export default function ProjectNews({ project, news, title }) {
  return (
    <>
      <div className="bg-white overflow-hidden mx-4 md:mx-10 mt-16">
        <div className="relative max-w-lg mx-auto divide-gray-200 lg:max-w-7xl">
          <div className="md:flex justify-between items-center">
            <h2 className="text-base text-lg md:text-2xl text-green-600 font-semibold tracking-wide uppercase">
              {title} News
            </h2>
            <h2 className="w-40 text-sm md:text-lg  text-gray-900">
              <Link to={`/${project.slug}`}>See More →</Link>{" "}
            </h2>
          </div>
          <div className="mt-6 grid gap-x-6 gap-y-8 grid-cols-2 lg:grid-cols-4 lg:gap-x-5 lg:gap-y-4">
            {news.nodes.map(post => {
              return (
                <NewsItem hideCategory={true} post={post} key={post.title} />
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}
