import React from "react"

export default function MissionVision({ data, isCoverImage = false }) {
  return (
    <>
      {/*mobile view*/}
      {!isCoverImage ? (
        <div className="md:hidden">
          <div>{data.image}</div>
          <div className="px-5 pt-5 pb-10">
            <h3 className="mt-5 mb-2 text-2xl leading-8 font-semibold tracking-tight sm:text-4xl">
              {data.title}
            </h3>
            <p
              className="text-lg mt-5 font-semibold"
              dangerouslySetInnerHTML={{ __html: data.description }}
            ></p>
          </div>
        </div>
      ) : (
        <div className="h-80 md:hidden static">
          <div className="relative">
            <div className="absolute flex w-full h-80 items-center justify-center z-10 backdrop-fade">
              <p className="text-white font-semibold text-4xl lg:text-3xl drop-shadow-2xl glow text-center ">
                {data.title}
              </p>
            </div>
          </div>
          {data.imageCover}
        </div>
      )}

      {/*tablet view*/}
      <div
        className={`hidden md:flex items-center  ${data.direction} lg:hidden`}
      >
        <div className="flex-1">
          <div className="pl-7 pr-7">
            <h3 className="mb-1 text-center text-2xl leading-8 font-semibold pt-2">
              {data.title}
            </h3>
            <p
              className="text-base text-center"
              dangerouslySetInnerHTML={{ __html: data.description }}
            ></p>
          </div>
        </div>
        <div className="flex-1 w-full flex-col-reverse">{data.image}</div>
      </div>

      {/*desktop view*/}
      <div
        className={`hidden md:hidden lg:flex ${data.direction}  w-full items-center`}
      >
        <div className="flex-1">
          <div className="pl-7 pr-7">
            <h3 className="mb-5 text-center text-4xl leading-normal font-semibold pt-2">
              {data.title}
            </h3>
            <p
              className="text-2xl text-center"
              dangerouslySetInnerHTML={{ __html: data.description }}
            ></p>
          </div>
        </div>
        <div className="flex-1 flex-col-reverse">{data.image}</div>
      </div>
    </>
  )
}
