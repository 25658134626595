import React from "react"

export default function SubHeader({ text }) {
  return (
    <>
      <div className="relative mx-auto full-screen md:h-auto">
        <p
          className="
					text-center text-gray-800 font-medium p-10 pt-20 pb-0 text-lg
					md:leading-relaxed md:p-10 md:pt-2 md:text-xl md:h-auto
					lg:pl-20 lg:pr-20 lg:font-medium lg:leading-normal lg:text-2xl lg:pt-25 lg:mt-44 lg:pb-52
					"
          dangerouslySetInnerHTML={{ __html: text }}
        ></p>
      </div>
    </>
  )
}
