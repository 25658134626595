import React from "react"
import parse from "html-react-parser"
export default function ProjectItems({ text, items }) {
  return (
    <>
      <div className="relative mx-auto full-screen md:h-auto">
        <div
          className="
					text-gray-800 font-medium p-2 pt-20 pb-0
					md:leading-relaxed md:p-10 md:pt-2 md:text-xl md:h-auto
					lg:pl-20 lg:pr-20 lg:font-medium lg:leading-normal lg:text-2xl lg:pt-25 lg:mt-44 lg:pb-52
					"
        >
          <p
            className="text-center"
            dangerouslySetInnerHTML={{ __html: text }}
          />
          <ul className="lg:mx-20 my-1 lg:my-5">
            {items?.split("\r\n").map((obj, i) => (
              <li className="text-center text-base" key={i}>
                &#8226; {obj}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  )
}
